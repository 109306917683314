import React from "react";
import styled from "styled-components";

import Faq from "./Faq";

const StyledFaqs = styled.ul`
  list-style: none;
`;

const FaqsList = ({ data = [] }) => (
  <StyledFaqs>
    {data.map((faq, i) => (
      <Faq key={`faq_${i}`} question={faq.question} answer={faq.answer} />
    ))}
  </StyledFaqs>
);

export default FaqsList;
