import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// import Banner from "../components/Banner";
import Layout from "components/Layout";
import SEO from "components/Seo";
import Faqs from "components/Faqs/FaqsList";

const FaqsPage = () => {
  const data = useStaticQuery(graphql`
    query faqsPageQuery {
      prismicFaqslist {
        data {
          title {
            text
          }
          faqs {
            question {
              text
            }
            answer {
              html
            }
          }
        }
      }
    }
  `);

  const IS_BROWSER = typeof window !== "undefined";
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const staticData = data;

  const contentMerged = mergePrismicPreviewData({ staticData, previewData });
  const content = contentMerged.prismicFaqslist.data;

  return (
    <Layout pageInfo={{ pageName: "faqs" }}>
      <SEO title="FAQs" />
      {/* <Banner title="FAQs" /> */}

      <section>
        <Container>
          <Row>
            <Col>
              <h2>{content.title.text}</h2>

              <p>
                Below you'll find several of our most frequently asked
                questions.{" "}
                <b>Just click on the question to reveal the answer.</b>
              </p>

              <p className="pb-4">
                Note: If you don't see the info you're looking for, please don't
                hesitate to call or email us.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="10">
              <Faqs data={content.faqs} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default FaqsPage;
