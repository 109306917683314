import React, { useState } from "react";
import useResizeAware from "react-resize-aware";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io/index.esm";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { HTMLContent } from "components/Content";

const StyledFaq = styled.li`
  /* border-left: 5px solid #133165; */
  align-items: unset;
  border-bottom: 1px solid #133165;
  cursor: pointer;
  flex-direction: column;
  margin: 0;

  &::before {
    content: none;
  }

  div.faq-question {
    padding: 1rem;
  }
`;

const StyledAnswer = styled.div`
  background: rgba(220, 220, 220, 0.3);
  position: relative;
  height: auto;
  overflow: hidden;

  span {
    position: relative;
    padding: 1rem;
    display: block;
    border-left: 6px solid #133165;

    p {
      font-size: 14px;

      &:last-child {
        margin: 0;
      }
    }

    ol {
      li {
        font-size: 14px;
      }
    }
  }
`;

const springConfig = {
  // duration: 500,
  // easing: easeBackOut,
  mass: 1,
  tension: 210,
  friction: 30,
};

const AnimatedStyledAnswer = animated(StyledAnswer);

const Faq = props => {
  const [isOpen, toggleOpen] = useState(false);
  const [resizeListener, { height }] = useResizeAware();
  const { question, answer } = props;

  const animProps = useSpring({
    config: springConfig,
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
  });

  return (
    <StyledFaq onClick={() => toggleOpen(!isOpen)}>
      <div className="faq-question">
        <span>
          {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />} {question.text}
        </span>
      </div>

      <AnimatedStyledAnswer className="faq-answer" style={{ ...animProps }}>
        <span>
          {resizeListener}
          <HTMLContent content={answer.html} />
        </span>
      </AnimatedStyledAnswer>
    </StyledFaq>
  );
};

export default Faq;
